import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThrottlingConfig } from "@quickcommerceltd/zone";
import { useFormik } from "formik";
import { FC, useState } from "react";
import shortid from "shortid";
import ColorPicker from "../ColorPicker/ColorPicker";
import IconPicker from "../IconPicker/IconPicker";
import { DEFAULT_DELIVERY_TIME } from "./defaults";
import { WarehouseActiveTimesView } from "./WarehouseActiveTimesView";

interface Props {
  value?: ThrottlingConfig;
  onClose: () => void;
  onSubmit: (value: ThrottlingConfig) => void;
  onDelete?: () => void;
}

export const StoreThrottlingForm: FC<Props> = (props) => {
  const [isMessageEnabled, setIsMessageEnabled] = useState(
    !!props.value?.message,
  );
  const [isActiveTimesEnabled, setIsActiveTimesEnabled] = useState(
    !!props.value?.activeTimes,
  );

  const defaultConfig: ThrottlingConfig = {
    id: shortid.generate(),
    stackSize: 10,
    enabled: true,
    deliveryTime: DEFAULT_DELIVERY_TIME,
    message: "",
    color: "orange100",
    icon: "info",
    closeTemporary: false,
    reopenTime: 5,
    activeTimes: null,
  };

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      ...defaultConfig,
      ...props.value,
    },
    onSubmit: props.onSubmit,
  });

  const handleIsMessageEnabledChange = (event: any) => {
    setIsMessageEnabled(event.target.checked);
    if (!event.target.checked) {
      setFieldValue("message", "");
      setFieldValue("icon", "info");
      setFieldValue("color", "orange100");
    }
  };

  const handleIsActiveTimesEnabledChange = (event: any) => {
    setIsActiveTimesEnabled(event.target.checked);
    if (event.target.checked) {
      setFieldValue("activeTimes", []);
    } else {
      setFieldValue("activeTimes", null);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Orders stack size"
                variant="outlined"
                size="small"
                name="stackSize"
                value={values.stackSize}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                label="Close store"
                control={
                  <Checkbox
                    color="primary"
                    checked={values.closeTemporary}
                    name="closeTemporary"
                    onChange={handleChange}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              {values.closeTemporary && (
                <TextField
                  fullWidth
                  type="number"
                  label="Reopen after x minutes"
                  variant="outlined"
                  size="small"
                  name="reopenTime"
                  disabled={!values.closeTemporary}
                  value={values.reopenTime}
                  onChange={handleChange}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                label="Show Message"
                control={
                  <Checkbox
                    color="primary"
                    checked={isMessageEnabled}
                    onChange={handleIsMessageEnabledChange}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              {isMessageEnabled && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <IconPicker
                      required
                      fullWidth
                      name="icon"
                      label="Icon"
                      value={values.icon}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                      required
                      fullWidth
                      name="color"
                      label="Color"
                      value={values.color}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            {isMessageEnabled && (
              <Grid item xs={12}>
                <TextField
                  required
                  size="small"
                  multiline
                  name="message"
                  label="Message"
                  fullWidth
                  variant="outlined"
                  value={values.message}
                  onChange={handleChange}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                label="Scheduled"
                control={
                  <Checkbox
                    color="primary"
                    checked={isActiveTimesEnabled}
                    onChange={handleIsActiveTimesEnabledChange}
                  />
                }
              />
            </Grid>
            {isActiveTimesEnabled && (
              <Grid item xs={12}>
                <WarehouseActiveTimesView
                  activeTimes={values.activeTimes}
                  updateActiveTimes={(activeTimes) =>
                    setFieldValue("activeTimes", activeTimes)
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end" alignItems="center">
          {props.onDelete && (
            <Box mx={2}>
              <IconButton color="primary" onClick={props.onDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
          <Button color="primary" variant="contained" type="submit">
            {props.onDelete ? "Save" : "Create"}
          </Button>
        </Box>
      </Card>
    </form>
  );
};
