import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  isWarehousePath,
  Warehouse,
  WarehouseActiveTime,
  WarehousePath,
  WarehousePolygon,
  warehousePolygonSchema,
} from "@quickcommerceltd/zone";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { useFormik } from "formik";
import keyBy from "lodash/keyBy";
import { DateTime } from "luxon";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import * as yup from "yup";
import { db } from "../../firebase";
import { useAuth } from "../Auth/AuthProvider";
import { getDistanceInMiles } from "./getDistanceInMiles";
import { usePolygons } from "./PolygonsProvider";
import { WarehouseActiveTimesView } from "./WarehouseActiveTimesView";
import {
  getResettedWarehousePathPoints,
  getWarehousePath,
} from "./WarehouseHelper";
import { useWarehouses } from "./WarehousesProvider";

interface Props {
  warehouse: Warehouse;
  polygon: WarehousePolygon;
}

const validationSchema = yup.object().shape({
  isDraft: yup.boolean(),
  polygon: warehousePolygonSchema,
});

const WarehousePolygonView: FC<Props> = ({ warehouse, polygon }) => {
  const { claims } = useAuth();
  const isAdmin = claims?.admin;
  const classes = useStyles();
  const navigate = useNavigate();
  const [polygons] = usePolygons();
  const [warehouses] = useWarehouses();
  const [isoWeekday, setIsoWeekday] = useState(DateTime.local().weekday);
  const [minute, setMinute] = useState(
    DateTime.local().hour * 60 + Math.floor(DateTime.local().minute / 5) * 5,
  );

  const { values, errors, isSubmitting, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        polygon,
        isDraft: warehouse.isDraft,
      },
      validationSchema,
      onSubmit: () => {
        return saveWarehouseMap();
      },
    });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.warn("errors", errors);
    }
  }, [errors]);

  const warehousesSet = useMemo(() => keyBy(warehouses, "id"), [warehouses]);

  const [isAutoPolygonSelectEnabled, setIsAutoPolygonSelectEnabled] =
    useState(true);

  const [selectedPath, setSelectedPath] = useState(
    getWarehousePath(values.polygon, isoWeekday, minute),
  );

  const centerRef = useRef<google.maps.LatLngLiteral>({
    lat: warehouse.pickUpAddress.latitude,
    lng: warehouse.pickUpAddress.longitude,
  });

  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);
  const marker = { url: "/marker.svg" };
  const markerSmall = {
    url: "/marker.svg",
    scaledSize: new google.maps.Size(24, 34),
  };

  /**
   * On update time slider.
   */
  useEffect(() => {
    if (isAutoPolygonSelectEnabled) {
      setSelectedPath(getWarehousePath(values.polygon, isoWeekday, minute));
    }
  }, [
    warehouse,
    values.polygon,
    minute,
    isoWeekday,
    isAutoPolygonSelectEnabled,
  ]);

  /**
   * Update active times.
   */
  const updateActiveTimes = (nextActiveTimes: WarehouseActiveTime[]) => {
    if (!selectedPath) return;

    setSelectedPath({
      ...selectedPath,
      activeTimes: nextActiveTimes,
    });

    setFieldValue("polygon", {
      ...values.polygon,
      paths: values.polygon.paths.map((path) => {
        return path.id === selectedPath.id
          ? { ...path, activeTimes: nextActiveTimes }
          : path;
      }),
    });
  };

  /**
   * Add warehouse path.
   */
  const addWarehousePath = () => {
    const newWarehousePath: WarehousePath = {
      ...selectedPath,
      id: v4(),
      isAlwaysActive: selectedPath?.isAlwaysActive ?? false,
      name: selectedPath ? `${selectedPath.name} clone` : `New polygon`,
      points: selectedPath
        ? JSON.parse(JSON.stringify(selectedPath.points))
        : getResettedWarehousePathPoints(
            warehouse.pickUpAddress.latitude,
            warehouse.pickUpAddress.longitude,
          ),
    };

    setFieldValue("polygon", {
      ...values.polygon,
      paths: [...values.polygon.paths, newWarehousePath],
    });

    setSelectedPath(newWarehousePath);
  };

  /**
   * Remove warehouse path.
   */
  const removeWarehousePath = () => {
    const { paths } = values.polygon;

    if (!selectedPath) return alert("There is no selected polygon.");
    if (paths.length === 1) return alert("There must be at least one polygon.");

    setFieldValue("polygon", {
      ...values.polygon,
      paths: paths.filter((path) => path.id !== selectedPath.id),
    });

    setSelectedPath(paths[0]);
  };

  /**
   * On change polygon.
   */
  const onChangePolygon = useCallback(() => {
    if (!selectedPath) return alert("There is no selected polygon.");

    if (polygonRef.current) {
      const nextPathPoints = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      setSelectedPath({ ...selectedPath, points: nextPathPoints });
    }
  }, [selectedPath]);

  /**
   * On Goolge API loaded.
   */
  const onLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon;

      const path = polygon.getPath();

      // Delete path point by right-click
      google.maps.event.addListener(polygon, "contextmenu", (e: any) => {
        if (!selectedPath) return;

        let closestDistance = Infinity;
        let closestIndex = -1;

        polygon.getPath().forEach((point, index) => {
          const nextDistance = getDistanceInMiles(
            { latitude: point.lat(), longitude: point.lng() },
            { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
          );

          if (nextDistance < closestDistance) {
            closestDistance = nextDistance;
            closestIndex = index;
          }
        });

        if (closestIndex >= 0) {
          polygon.getPath().removeAt(closestIndex);
          onChangePolygon();
        }
      });

      listenersRef.current.push(
        path.addListener("set_at", onChangePolygon),
        path.addListener("insert_at", onChangePolygon),
        path.addListener("remove_at", onChangePolygon),
      );
    },
    [selectedPath, onChangePolygon],
  );

  /**
   * Reset path points.
   */
  const resetPathPoints = () => {
    if (!selectedPath) return alert("There is no selected polygon.");

    setSelectedPath({
      ...selectedPath,
      points: getResettedWarehousePathPoints(
        warehouse.pickUpAddress.latitude,
        warehouse.pickUpAddress.longitude,
      ),
    });
  };

  /**
   * On unmount component.
   */
  const onUnmount = () => {
    listenersRef.current.forEach((listener) => listener.remove());
    polygonRef.current = null;
  };

  /**
   * Save.
   */
  const saveWarehouseMap = async () => {
    if (!isAdmin) {
      console.warn("Can not write warehouse document");
      return;
    }

    const isValid = values.polygon.paths.every((path) => isWarehousePath(path));

    if (!isValid) {
      return alert(
        "Invalid data. Check if times start with zero e.g. '06:00' instead of '6:00'!",
      );
    }

    const isConfirmed = window.confirm(
      `Are you sure you want to override ${warehouse.id}`,
    );

    if (isConfirmed) {
      try {
        const updatedPaths = values.polygon.paths.map((path) => {
          const newId = v4();
          let updatedPath = { ...path, id: newId };

          if (updatedPath.deliveryTime === undefined) {
            delete updatedPath.deliveryTime;
          }

          if (selectedPath?.id === path.id) {
            updatedPath = { ...updatedPath, ...selectedPath, id: newId };
            setSelectedPath(updatedPath);
          }

          return { ...updatedPath };
        });

        const nextWarehouse: Partial<Warehouse> = {
          isDraft: values.isDraft,
        };

        await db
          .collection("warehouses")
          .doc(warehouse.id)
          .set({ ...nextWarehouse }, { merge: true });

        const nextWarehousePolygon: Partial<WarehousePolygon> = {
          paths: updatedPaths,
        };

        await db
          .collection("warehousePolygons")
          .doc(warehouse.id)
          .set({ ...nextWarehousePolygon }, { merge: true });

        alert("Update was successful");
      } catch (error: any) {
        alert(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={7} lg={8} xl={9}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>Weekday</Typography>
              <Slider
                key={isoWeekday}
                defaultValue={isoWeekday}
                onChangeCommitted={(_, value) =>
                  typeof value === "number" && setIsoWeekday(value)
                }
                valueLabelFormat={(value) =>
                  DateTime.local().set({ weekday: value }).weekdayShort
                }
                valueLabelDisplay="auto"
                marks
                min={1}
                max={7}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography>Time</Typography>
              <Slider
                key={minute}
                defaultValue={minute}
                onChangeCommitted={(_, value) =>
                  typeof value === "number" && setMinute(value)
                }
                valueLabelFormat={(value) =>
                  DateTime.local()
                    .set({
                      minute: value % 60,
                      hour: Math.floor(value / 60),
                    })
                    .toLocaleString(DateTime.TIME_24_SIMPLE)
                }
                valueLabelDisplay="auto"
                step={5}
                min={0}
                max={60 * 24 - 5}
              />
            </Grid>
          </Grid>
          <Box width="100%" height={600}>
            <GoogleMap
              mapContainerClassName={classes.mapContainer}
              center={centerRef.current}
              zoom={13}
            >
              {(polygons ?? [])
                .filter((item) => item.id !== warehouse.id)
                .map((item) => {
                  const path = getWarehousePath(item, isoWeekday, minute);

                  if (!path) return null;

                  return (
                    <Polygon
                      key={item.id}
                      path={path.points}
                      options={{
                        fillColor: warehousesSet[item.id]?.isDraft
                          ? "#101010"
                          : warehousesSet[item.id]?.isTemporarilyClosed
                          ? "#FF0000"
                          : "#2cc976",
                      }}
                      onMouseDown={() => {
                        console.log("polygon", item);
                      }}
                    />
                  );
                })}
              <Polygon
                key={selectedPath?.id || "none"}
                editable={isAdmin}
                options={{
                  fillColor: values.isDraft
                    ? "#101010"
                    : warehouse.isTemporarilyClosed
                    ? "#FF0000"
                    : "#2cc976",
                  fillOpacity: 0.5,
                  strokePosition: google.maps.StrokePosition.OUTSIDE,
                }}
                path={selectedPath?.points || []}
                onMouseUp={onChangePolygon}
                onDragEnd={onChangePolygon}
                onLoad={onLoad}
                onUnmount={onUnmount}
              />
              {warehouses?.map((warehouse) => (
                <Marker
                  icon={markerSmall}
                  key={warehouse.id}
                  title={warehouse.id}
                  opacity={0.9}
                  position={{
                    lat: warehouse.pickUpAddress.latitude,
                    lng: warehouse.pickUpAddress.longitude,
                  }}
                  onClick={() => {
                    navigate(`/warehouse/${warehouse.id}/polygon`);
                  }}
                />
              ))}

              <Marker
                icon={marker}
                position={{
                  lat: warehouse.pickUpAddress.latitude,
                  lng: warehouse.pickUpAddress.longitude,
                }}
              />
            </GoogleMap>
          </Box>
        </Grid>
        <Grid item sm={12} md={5} lg={4} xl={3}>
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAutoPolygonSelectEnabled}
                  onChange={(event) =>
                    setIsAutoPolygonSelectEnabled(event.currentTarget.checked)
                  }
                  color="primary"
                />
              }
              label="Auto select polygon"
            />
          </Box>
          {!isAutoPolygonSelectEnabled && (
            <>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="polygon">Polygon</InputLabel>
                <Select
                  native
                  renderValue={() => selectedPath?.name ?? "None"}
                  value={selectedPath?.id}
                  onChange={(event) => {
                    const nextSelectedPath = values.polygon.paths.find(
                      (path) => path.id === event.currentTarget.value,
                    )!;

                    setSelectedPath(nextSelectedPath);
                  }}
                  label="Polygon"
                  inputProps={{
                    name: "polygon",
                    id: "polygon",
                  }}
                  disabled={!isAdmin}
                >
                  <option value={undefined}>None</option>
                  {values.polygon.paths.map((path, index) => (
                    <option key={index} value={path.id}>
                      {path.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Box display="flex" justifyContent="space-between">
                <Button
                  disabled={!isAdmin}
                  color="primary"
                  size="small"
                  onClick={addWarehousePath}
                >
                  {selectedPath ? "Clone" : "Add"}
                </Button>
                {selectedPath && (
                  <Button
                    color="secondary"
                    size="small"
                    disabled={!isAdmin}
                    onClick={removeWarehousePath}
                  >
                    Delete
                  </Button>
                )}
              </Box>
              {selectedPath && (
                <>
                  <Box mt={2}>
                    <TextField
                      onChange={(event) => {
                        setSelectedPath({
                          ...selectedPath,
                          name: event.currentTarget.value,
                        });
                      }}
                      value={selectedPath.name}
                      label="Name"
                      variant="outlined"
                      fullWidth
                      disabled={!isAdmin}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      type="number"
                      value={selectedPath.deliveryTime ?? ""}
                      label="Delivery Time"
                      variant="outlined"
                      name="deliveryTime"
                      fullWidth
                      disabled={!isAdmin}
                      inputProps={{ min: 0, max: 100 }}
                      onChange={(event) => {
                        try {
                          const deliveryTime = parseInt(
                            event.currentTarget.value,
                            10,
                          );

                          if (
                            !Number.isFinite(deliveryTime) ||
                            deliveryTime <= 0
                          ) {
                            throw Error(
                              `"${event.currentTarget.value}" is not a number.`,
                            );
                          }

                          setSelectedPath({
                            ...selectedPath,
                            deliveryTime,
                          });
                        } catch (error: any) {
                          setSelectedPath({
                            ...selectedPath,
                            deliveryTime: undefined,
                          });
                        }
                      }}
                    />
                  </Box>
                  <Box py={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!isAdmin}
                          checked={!!selectedPath.isDraft}
                          color="primary"
                          onChange={(event) => {
                            setSelectedPath({
                              ...selectedPath,
                              isDraft: event.currentTarget.checked,
                            });
                          }}
                        />
                      }
                      label="Draft Polygon"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!isAdmin}
                          checked={selectedPath.isAlwaysActive}
                          color="primary"
                          onChange={(event) => {
                            setSelectedPath({
                              ...selectedPath,
                              isAlwaysActive: event.currentTarget.checked,
                            });
                          }}
                        />
                      }
                      label="24/7"
                    />
                  </Box>
                  {!selectedPath.isAlwaysActive && (
                    <WarehouseActiveTimesView
                      activeTimes={selectedPath.activeTimes}
                      updateActiveTimes={updateActiveTimes}
                    />
                  )}
                </>
              )}
            </>
          )}
          <Box mb={2} mt={2} display="flex" flexDirection="row">
            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isAdmin}
                onClick={resetPathPoints}
              >
                Reset
              </Button>
            </Box>
            <Box mr={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isAdmin}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default WarehousePolygonView;

const useStyles = makeStyles(() => ({
  mapContainer: {
    width: "100%",
    height: "100%",
  },
}));
