import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  StorefrontData,
  StorefrontProvider,
  StorefrontStatus,
  Warehouse,
} from "@quickcommerceltd/zone";
import { useFormik } from "formik";
import { FC, useMemo, useState } from "react";
import * as yup from "yup";
import { useAuth } from "../Auth/AuthProvider";
import { updateStorefrontStatusCallable } from "./callables/updateStorefrontStatusCallable";
import { StorefrontItem } from "./components/StorefrontItem";
import { WarehouseAddressForm } from "./components/WarehouseAddressForm";
import { getWarehouseStatus, WarehouseStatus } from "./getWarehouseStatus";
import { useUpdateWarehouseStatus } from "./hooks/useUpdateWarehouseStatus";
import WarehouseStatusModal from "./WarehouseStatusModal";
import { WarehouseStatusView } from "./WarehouseStatusView";

interface Props {
  warehouse: Warehouse;
}

type FormValues = {
  warehouseStatus: WarehouseStatus;
  reason: string;
};

const validationSchema = yup.object().shape({
  warehouseStatus: yup.string().required(),
  reason: yup.string().required(),
});

export const WarehouseSettingsView: FC<Props> = (props) => {
  const auth = useAuth();
  const warehouseStatus = getWarehouseStatus(props.warehouse);
  const [updateWarehouseStatus, isUpdatingWarehouseStatus] =
    useUpdateWarehouseStatus();
  const [isCloseStoreModalVisible, setIsCloseStoreModalVisible] =
    useState(false);

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik<FormValues>({
      validationSchema,
      initialValues: {
        warehouseStatus,
        reason: props.warehouse.manuallyClosedReason || "Opened store",
      },
      onSubmit: async (values: FormValues) => {
        await updateWarehouseStatus({
          warehouseId: props.warehouse.id,
          status: values.warehouseStatus,
          reason: values.reason,
        });

        setIsCloseStoreModalVisible(false);
      },
    });

  const storefronts = useMemo(() => {
    if (!props.warehouse.storefronts) return [];
    return Object.entries(props.warehouse.storefronts) as [
      StorefrontProvider,
      StorefrontData,
    ][];
  }, [props.warehouse]);

  const handleStorefrontStatusChange = (
    provider: StorefrontProvider,
    status: StorefrontStatus,
  ) => {
    return updateStorefrontStatusCallable({
      warehouseId: props.warehouse.id,
      provider,
      status,
    });
  };

  const isAdmin = Boolean(auth.claims?.admin);
  const isManager = Boolean(auth.claims?.manager);
  const isAdminOrManager = isAdmin || isManager;

  return (
    <Grid container>
      <Grid item sm={12} md={6}>
        <Box mb={1}>
          <Typography variant="h6">Status</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              name="warehouseStatus"
              value={values.warehouseStatus}
              error={!!errors.warehouseStatus}
              onChange={(e, value) => {
                handleChange(e);
                if (warehouseStatus !== value) {
                  setIsCloseStoreModalVisible(true);
                }
              }}
            >
              <MenuItem value="DRAFT" disabled={!isAdmin}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <WarehouseStatusView warehouseStatus="DRAFT" />
                  <Box ml={1}>Draft</Box>
                </Box>
              </MenuItem>
              <MenuItem value="CLOSED" disabled={!isAdminOrManager}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <WarehouseStatusView warehouseStatus="CLOSED" />
                  <Box ml={1}>Closed</Box>
                </Box>
              </MenuItem>
              <MenuItem value="OPEN" disabled={!isAdminOrManager}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <WarehouseStatusView warehouseStatus="OPEN" />
                  <Box ml={1}>Open</Box>
                </Box>
              </MenuItem>
            </Select>
            <WarehouseStatusModal
              isLoading={isUpdatingWarehouseStatus}
              isOpen={isCloseStoreModalVisible}
              nextWarehouseStatus={values.warehouseStatus}
              onSubmit={(reason: string) => {
                setFieldValue("reason", reason);
                handleSubmit();
              }}
              onClose={() => {
                setIsCloseStoreModalVisible(false);
                setFieldValue("warehouseStatus", warehouseStatus);
              }}
            />
          </FormControl>
        </form>
      </Grid>
      <Grid item sm={12} hidden={!storefronts.length}>
        <Box my={2}>
          <Box mb={1}>
            <Typography variant="h6">Storefronts</Typography>
          </Box>

          <Grid container spacing={2}>
            {storefronts.map(([provider, data]) => (
              <Grid item sm={12} md={6} key={provider}>
                <StorefrontItem
                  provider={provider}
                  data={data}
                  onStorefrontStatusChanged={handleStorefrontStatusChange}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
      <Grid item sm={12} md={12}>
        <Box my={2}>
          <Box mb={1}>
            <Typography variant="h6">Address</Typography>
          </Box>

          <WarehouseAddressForm warehouse={props.warehouse} />
        </Box>
      </Grid>
    </Grid>
  );
};
