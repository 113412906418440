import { Box, createTheme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { StoreWarning } from "@quickcommerceltd/zone";
import { warningIcons } from "./WarningIcons";

const defaultTheme = createTheme();

export const iconColors: { [key: string]: string } = {
  blue50: defaultTheme.palette.info.main,
  green50: defaultTheme.palette.success.main,
  orange100: defaultTheme.palette.warning.main,
  red50: defaultTheme.palette.error.main,
};

const useStyles = makeStyles((theme) => ({ icon: { display: "block" } }));

const WarehouseWarning = ({ warning }: { warning: StoreWarning }) => {
  const iconName = warning.icon || "clock";
  const Icon = warningIcons[iconName] || null;
  const classes = useStyles();

  return (
    <Tooltip arrow title={warning.message}>
      <Box margin={-1} padding={1}>
        <Icon
          width={16}
          height={16}
          className={classes.icon}
          style={{ color: iconColors[warning.color] }}
        />
      </Box>
    </Tooltip>
  );
};

export default WarehouseWarning;
