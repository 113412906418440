import { Button, ListItem, Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { SyntheticEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import firebase from "../../firebase";
import { useAuth } from "../Auth/AuthProvider";

export const UserDropdown = () => {
  const navigate = useNavigate();
  const { user, claims } = useAuth();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    handleClose();
    firebase
      .auth()
      .signOut()
      .then(() => navigate("/"));
  };

  return (
    <span style={{ position: "relative" }}>
      {user && (
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="text"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
        >
          {user?.email?.split("@")[0]}
        </Button>
      )}

      <Menu
        id="customized-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        PaperProps={{ style: { minWidth: 220 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ListItem disabled>{user?.email}</ListItem>
        {claims?.admin && (
          <MenuItem component={Link} to="/authorize">
            Authorize
          </MenuItem>
        )}

        <MenuItem onClick={signOut}>Log out</MenuItem>
      </Menu>
    </span>
  );
};
