import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Authorize from "../Auth/Authorize";
import WarehouseCreateView from "../Warehouse/WarehouseCreateView";
import { WarehouseMessagesPage } from "../Warehouse/WarehouseMessagesPage";
import { WarehousePage } from "../Warehouse/WarehousePage";
import { WarehousePolygonPage } from "../Warehouse/WarehousePolygonPage";
import { WarehouseSettingsPage } from "../Warehouse/WarehouseSettingsPage";

export const AppRouter: FC = () => {
  return (
    <Routes>
      <Route index element={<WarehouseCreateView />} />
      <Route path="warehouse/:warehouseId" element={<WarehousePage />}>
        <Route path="polygon" element={<WarehousePolygonPage />} />
        <Route path="messages" element={<WarehouseMessagesPage />} />
        <Route path="settings" element={<WarehouseSettingsPage />} />
      </Route>
      <Route path="authorize" element={<Authorize />} />
    </Routes>
  );
};
