import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { WarehouseActiveTime } from "@quickcommerceltd/zone";
import { DateTime } from "luxon";
import { FC } from "react";
import { useAuth } from "../Auth/AuthProvider";

interface Props {
  activeTimes?: WarehouseActiveTime[] | null;
  updateActiveTimes: (nextActiveTimes: WarehouseActiveTime[]) => void;
}

export const WarehouseActiveTimesView: FC<Props> = (props) => {
  const auth = useAuth();
  const isAdmin = !!auth.claims?.admin;

  /**
   * Update active time.
   */
  const updateActiveTime = (
    index: number,
    update: Partial<WarehouseActiveTime>,
  ) => {
    props.updateActiveTimes(
      (props.activeTimes || []).map((activeTime, i) =>
        i === index ? { ...activeTime, ...update } : activeTime,
      ),
    );
  };

  /**
   * Add active time.
   */
  const addActiveTime = () => {
    const newActiveTime: WarehouseActiveTime = {
      from: "00:00",
      to: "24:00",
      isoWeekday: DateTime.local().weekday,
    };

    props.updateActiveTimes([...(props.activeTimes || []), newActiveTime]);
  };

  /**
   * Remove active time.
   */
  const removeActiveTime = (activeTime: WarehouseActiveTime) => {
    props.updateActiveTimes(
      (props.activeTimes || [])?.filter((time) => time !== activeTime),
    );
  };

  return (
    <Box mt={-2}>
      {props.activeTimes
        ?.sort((a, b) => a.isoWeekday - b.isoWeekday)
        .map((activeTime, index) => (
          <Box key={index} display="flex" flexDirection="row" mt={2}>
            <Box flex={1}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel htmlFor={`weekday-${index}`}>Weekday</InputLabel>
                <Select
                  native
                  disabled={!isAdmin}
                  renderValue={() => activeTime.isoWeekday}
                  value={activeTime.isoWeekday}
                  onChange={(event) =>
                    updateActiveTime(index, {
                      isoWeekday: parseInt(event.currentTarget.value as string),
                    })
                  }
                  label="Weekday"
                  inputProps={{
                    name: `weekday-${index}`,
                    id: `weekday-${index}`,
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 7].map((isoWeekday) => (
                    <option key={isoWeekday} value={isoWeekday}>
                      {
                        DateTime.local().set({
                          weekday: isoWeekday,
                        }).weekdayLong
                      }
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box ml={1} flex={1}>
              <TextField
                disabled={!isAdmin}
                onChange={(event) =>
                  updateActiveTime(index, {
                    from: event.currentTarget.value,
                  })
                }
                value={activeTime.from}
                label="From"
                size="small"
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box ml={1} flex={1}>
              <TextField
                disabled={!isAdmin}
                onChange={(event) =>
                  updateActiveTime(index, {
                    to: event.currentTarget.value,
                  })
                }
                value={activeTime.to}
                label="To"
                size="small"
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box ml={1}>
              <IconButton
                disabled={!isAdmin}
                aria-label="delete"
                onClick={() => removeActiveTime(activeTime)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ))}
      <Box mt={2}>
        <Button
          disabled={!isAdmin}
          color="primary"
          size="small"
          fullWidth
          onClick={() => addActiveTime()}
        >
          Add hours
        </Button>
      </Box>
    </Box>
  );
};
