import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { StoreWarning, ThrottlingConfig } from "@quickcommerceltd/zone";
import firebase from "firebase/compat/app";
import orderBy from "lodash/orderBy";
import { FC, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../../firebase";
import { useAuth } from "../Auth/AuthProvider";
import { DEFAULT_DELIVERY_TIME } from "./defaults";
import { useWarehouseFromRoute } from "./hooks/useWarehouseFromRoute";
import { StoreThrottlingForm } from "./StoreThrottlingForm";
import { StoreThrottlingItem } from "./StoreThrottlingItem";
import { StoreWarningForm } from "./StoreWarningForm";
import { StoreWarningItem } from "./StoreWarningItem";

export const WarehouseMessagesPage: FC = () => {
  const auth = useAuth();
  const warehouse = useWarehouseFromRoute();
  const isAdmin = auth.claims?.admin;
  const canToggle = isAdmin || auth.claims?.manager;
  const warehouses = db.collection("warehouses");

  const [isCreatingCustomMessage, setCreatingCustomMessage] = useState(false);
  const [isCreatingThrottlingForm, setCreatingThrottlingForm] = useState(false);

  const [throttlingConfigs, isLoadingThrottlingConfigs] =
    useCollectionData<ThrottlingConfig>(
      warehouse
        ? warehouses
            .doc(warehouse.id)
            .collection("throttlingConfigs")
            .limit(100)
        : null,
    );

  const [storeWarnings, isLoadingStoreWarnings] =
    useCollectionData<StoreWarning>(
      warehouse
        ? warehouses.doc(warehouse.id).collection("storeWarnings").limit(100)
        : null,
    );

  if (!warehouse) return null;

  if (isLoadingThrottlingConfigs || isLoadingStoreWarnings) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item sm={12} md={6}>
        <Box minHeight={40} mb={1}>
          <Typography variant="h6">Store custom messages</Typography>
        </Box>

        <Button
          fullWidth
          variant="outlined"
          disabled={!isAdmin}
          onClick={() => setCreatingCustomMessage(!isCreatingCustomMessage)}
        >
          Add template
        </Button>

        <Box mb={2} />

        {isCreatingCustomMessage && (
          <StoreWarningForm
            onClose={() => {
              setCreatingCustomMessage(false);
            }}
            onSubmit={(value) => {
              warehouses
                .doc(warehouse.id)
                .collection(`storeWarnings`)
                .doc(value.id)
                .set(value);

              warehouses
                .doc(warehouse.id)
                .update({ lastChangeBy: auth.user?.email });

              setCreatingCustomMessage(false);
            }}
          />
        )}

        {(storeWarnings || []).map((warning: StoreWarning) => (
          <StoreWarningItem
            key={warning.id}
            value={warning}
            disabled={!isAdmin}
            canToggle={canToggle}
            enabled={warehouse.warning?.id === warning.id}
            onToggle={async (enabled) => {
              if (enabled) {
                await warehouses.doc(warehouse.id).update({
                  isAutomaticThrottlingEnabled: false,
                });
              }
              await warehouses.doc(warehouse.id).update({
                warning: enabled
                  ? warning
                  : firebase.firestore.FieldValue.delete(),
                lastChangeBy: auth.user?.email,
              });
            }}
            onChange={(value) => {
              if (warehouse.warning?.id === warning.id) {
                warehouses.doc(warehouse.id).update({
                  warning: value,
                  lastChangeBy: auth.user?.email,
                });
              }
              warehouses
                .doc(warehouse.id)
                .collection(`storeWarnings`)
                .doc(value.id)
                .set(value);

              warehouses
                .doc(warehouse.id)
                .update({ lastChangeBy: auth.user?.email });
            }}
            onDelete={() => {
              warehouses
                .doc(warehouse.id)
                .collection(`storeWarnings`)
                .doc(warning.id)
                .delete();

              warehouses
                .doc(warehouse.id)
                .update({ lastChangeBy: auth.user?.email });

              if (warning.id === warehouse.warning?.id) {
                warehouses.doc(warehouse.id).update({
                  warning: firebase.firestore.FieldValue.delete(),
                  lastChangeBy: auth.user?.email,
                });
              }
            }}
          />
        ))}
      </Grid>

      <Grid item sm={12} md={6}>
        <Box minHeight={40} mb={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              Automated throttling{" "}
              <Tooltip
                arrow
                title="Enabling this setting will not open manually closed store"
              >
                <InfoIcon />
              </Tooltip>
            </Typography>
            <Switch
              disabled={!canToggle}
              color="primary"
              name="isAutomaticThrottlingEnabled"
              checked={warehouse.isAutomaticThrottlingEnabled ?? false}
              onChange={(e) => {
                if (e.target.checked) {
                  warehouses.doc(warehouse.id).update({
                    warning: firebase.firestore.FieldValue.delete(),
                    lastChangeBy: auth.user?.email,
                    isAutomaticThrottlingEnabled: true,
                  });
                } else {
                  warehouses.doc(warehouse.id).update({
                    lastChangeBy: auth.user?.email,
                    deliveryTime: DEFAULT_DELIVERY_TIME,
                    isAutomaticThrottlingEnabled: false,
                  });
                }
              }}
            />
          </Box>
        </Box>

        <Button
          fullWidth
          variant="outlined"
          disabled={!isAdmin}
          onClick={() => setCreatingThrottlingForm(!isCreatingThrottlingForm)}
        >
          Add template
        </Button>

        {isCreatingThrottlingForm && (
          <StoreThrottlingForm
            onClose={() => {
              setCreatingThrottlingForm(false);
            }}
            onSubmit={(value) => {
              warehouses
                .doc(warehouse.id)
                .collection(`throttlingConfigs`)
                .doc(value.id)
                .set(value);

              warehouses
                .doc(warehouse.id)
                .update({ lastChangeBy: auth.user?.email });

              setCreatingCustomMessage(false);
              setCreatingThrottlingForm(false);
            }}
          />
        )}

        <Box mb={2} />

        {orderBy(throttlingConfigs || [], "stackSize").map((config) => (
          <StoreThrottlingItem
            disabled={!isAdmin}
            canToggle={canToggle}
            key={config.id}
            value={config}
            isActiveNow={warehouse.warning?.id === config.id}
            onChange={(value) => {
              warehouses
                .doc(warehouse.id)
                .collection(`throttlingConfigs`)
                .doc(value.id)
                .set(value);

              warehouses
                .doc(warehouse.id)
                .update({ lastChangeBy: auth.user?.email });
            }}
            onDelete={() => {
              if (config.id === warehouse.warning?.id) {
                warehouses.doc(warehouse.id).update({
                  warning: firebase.firestore.FieldValue.delete(),
                  lastChangeBy: auth.user?.email,
                });
              }

              warehouses
                .doc(warehouse.id)
                .collection(`throttlingConfigs`)
                .doc(config.id)
                .delete();

              warehouses
                .doc(warehouse.id)
                .update({ lastChangeBy: auth.user?.email });
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
};
