import React, { FC } from "react";

import { BrowserRouter } from "react-router-dom";
import AppView from "./AppView";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { AuthProvider } from "../Auth/AuthProvider";
import WarehousesProvider from "../Warehouse/WarehousesProvider";
import PolygonsProvider from "../Warehouse/PolygonsProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#02BAFF",
    },
  },
  overrides: {
    MuiSlider: {
      valueLabel: {
        zIndex: 1200,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        lineHeight: "1.2em",
      },
    },
    MuiButton: {
      text: { textTransform: "none", fontWeight: 600 },
      textSizeSmall: { textTransform: "uppercase" },
    },
  },
});

const AppProvider: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <WarehousesProvider>
          <PolygonsProvider>
            <BrowserRouter>
              <AppView />
            </BrowserRouter>
          </PolygonsProvider>
        </WarehousesProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
