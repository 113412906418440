import {
  Box,
  Card,
  FormControlLabel,
  IconButton,
  Switch,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import EventIcon from "@material-ui/icons/Event";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { ThrottlingConfig } from "@quickcommerceltd/zone";
import { FC, useState } from "react";
import { StoreThrottlingForm } from "./StoreThrottlingForm";
import { StoreWarningView } from "./StoreWarningView";

interface Props {
  value: ThrottlingConfig;
  disabled?: boolean;
  isActiveNow: boolean;
  onChange: (value: ThrottlingConfig) => void;
  onDelete: () => void;
  canToggle?: boolean;
}

export const StoreThrottlingItem: FC<Props> = ({
  value,
  disabled,
  isActiveNow,
  onChange,
  onDelete,
  canToggle,
}) => {
  const [isEditing, setEditing] = useState(false);
  return (
    <Box mb={2}>
      <Card
        style={{ boxShadow: isActiveNow ? "0 0 0 4px #02BAFF" : undefined }}
      >
        <Box
          display="flex"
          px={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <IconButton
              color="primary"
              disabled={disabled}
              onClick={() => {
                setEditing(!isEditing);
              }}
            >
              <Edit />
            </IconButton>
            <FormControlLabel
              label={value.enabled ? "On" : "Off"}
              control={
                <Switch
                  disabled={!canToggle}
                  color="primary"
                  checked={value.enabled}
                  onChange={() => {
                    onChange({ ...value, enabled: !value.enabled });
                  }}
                />
              }
            />
          </Box>
          <Box display="flex">
            {value.closeTemporary && (
              <Box mx={0.5} display="flex">
                <NotInterestedIcon fontSize="small" />
                &nbsp;Closing
              </Box>
            )}
            <Box mx={0.5} display="flex">
              <EventIcon fontSize="small" />
              &nbsp;{value.activeTimes ? "Scheduled" : "24/7"}
            </Box>
            <Box mx={0.5} display="flex">
              <ShoppingCartIcon fontSize="small" />
              &nbsp;{value.stackSize}&nbsp;orders
            </Box>
          </Box>
        </Box>

        {!isEditing && value.message && value.icon && value.color && (
          <StoreWarningView value={value} />
        )}
      </Card>
      {isEditing && (
        <StoreThrottlingForm
          value={value}
          onClose={() => setEditing(false)}
          onDelete={onDelete}
          onSubmit={(value) => {
            onChange(value);
            setEditing(false);
          }}
        />
      )}
    </Box>
  );
};
