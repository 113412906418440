import { Box, Divider, Tab, Tabs, Typography } from "@material-ui/core";
import { FC } from "react";
import { Link, Outlet } from "react-router-dom";
import AppTopBar from "../App/AppTopBar";
import { useAuth } from "../Auth/AuthProvider";
import ChipIsDC from "../ChipIsDC/ChipIsDC";
import { useWarehouseFromRoute } from "./hooks/useWarehouseFromRoute";
import { useWarehouseSubRoute } from "./hooks/useWarehouseSubRoute";
import { LimitedAccessBanner } from "./LimitedAccessBanner";
import WarehouseStatus from "./WarehouseStatus";

export const WarehousePage: FC = () => {
  const { claims } = useAuth();
  const isAdmin = claims?.admin;
  const canToggle = claims?.admin || claims?.manager;
  const warehouse = useWarehouseFromRoute();
  const subRoute = useWarehouseSubRoute();

  if (!warehouse) return <Box p={2}>Can not find the store</Box>;

  return (
    <>
      <AppTopBar>
        <Box flex={1}>
          <Typography variant="h6" noWrap>
            {warehouse.name}
            {warehouse?.isDistributionCenter && <ChipIsDC />}
          </Typography>
          <WarehouseStatus warehouse={warehouse} />
        </Box>
      </AppTopBar>
      {!isAdmin && <LimitedAccessBanner readOnly={!canToggle} />}
      <Tabs value={subRoute} indicatorColor="primary">
        <Tab
          label="Polygon"
          value="polygon"
          component={Link}
          to={`/warehouse/${warehouse.id}/polygon`}
        />
        <Tab
          label="Messages"
          value="messages"
          component={Link}
          to={`/warehouse/${warehouse.id}/messages`}
        />
        <Tab
          label="Settings"
          value="settings"
          component={Link}
          to={`/warehouse/${warehouse.id}/settings`}
        />
      </Tabs>
      <Divider />
      <Box p={2}>
        <Outlet />
      </Box>
    </>
  );
};
