import { FirebaseError } from "@firebase/util";
import { WarehousePolygon } from "@quickcommerceltd/zone";
import React, { FC, useContext } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../../firebase";

type PolygonsValue = [
  WarehousePolygon[] | undefined,
  boolean,
  FirebaseError | undefined,
];

const defaultValue: PolygonsValue = [[], false, undefined];

const PolygonsContext = React.createContext(defaultValue);

export const usePolygons = () => {
  const value = useContext(PolygonsContext);
  return value;
};

const PolygonsProvider: FC = ({ children }) => {
  const value = useCollectionData<WarehousePolygon>(
    db.collection("warehousePolygons"),
  );

  return (
    <PolygonsContext.Provider value={value}>
      {children}
    </PolygonsContext.Provider>
  );
};

export default PolygonsProvider;
