import { Box, CssBaseline, makeStyles } from "@material-ui/core";
import { FC } from "react";
import { AppRouter } from "./AppRouter";
import AppSidebarView, { SIDEBAR_WIDTH } from "./AppSidebarView";

const AppView: FC = () => {
  const classes = useStyles();

  return (
    <Box flex={1}>
      <CssBaseline />
      <AppSidebarView />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <AppRouter />
      </main>
    </Box>
  );
};

export default AppView;

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    marginLeft: SIDEBAR_WIDTH,
  },
}));
