import { Box, Card, IconButton, Grid, TextField } from "@material-ui/core";
import { StoreWarning } from "@quickcommerceltd/zone";
import shortid from "shortid";
import Check from "@material-ui/icons/Check";
import { FC } from "react";
import ColorPicker from "../ColorPicker/ColorPicker";
import IconPicker from "../IconPicker/IconPicker";
import { useFormik } from "formik";

interface Props {
  value?: StoreWarning;
  disabled?: boolean;
  onClose: () => void;
  onSubmit: (value: StoreWarning) => void;
}

export const StoreWarningForm: FC<Props> = (props) => {
  const defaultWarning = {
    id: shortid.generate(),
    message: "",
    color: "orange100",
    icon: "clock",
  };

  const { values, handleChange, handleSubmit } = useFormik({
    // validationSchema: storeWarningSchema,// TODO

    initialValues: {
      ...defaultWarning,
      ...props.value,
    },
    onSubmit: (values) => {
      // @ts-ignore
      props.onSubmit(values);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={3}>
        <Card>
          <Box padding={2} display="flex">
            <Box display="flex" flex={1}>
              <Box flex={1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <IconPicker
                      required
                      fullWidth
                      name="icon"
                      label="Icon"
                      value={values.icon}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                      required
                      name="color"
                      label="Color"
                      value={values.color}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                <Box mb={1} />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      size="small"
                      multiline
                      name="message"
                      label="Message"
                      fullWidth
                      variant="outlined"
                      value={values.message}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              ml={2}
            >
              <IconButton color="primary" type="submit">
                <Check />
              </IconButton>
            </Box>
          </Box>
        </Card>
      </Box>
    </form>
  );
};
