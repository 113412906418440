import { Box, Link } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FC } from "react";

interface Props {
  readOnly?: boolean;
}

export const LimitedAccessBanner: FC<Props> = ({ readOnly }) => {
  return (
    <Box mb={1}>
      <Alert severity="info">
        You have <b>{readOnly ? "read-only" : "limited"}</b> access. Please
        contact administrators to get an extended access:{" "}
        <Link
          target="_blank"
          href="https://quick-commerce.slack.com/archives/C02NGU43K4Y"
        >
          <b>#zapp-support-zone</b>
        </Link>
        .
      </Alert>
    </Box>
  );
};
