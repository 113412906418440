interface Coords {
  latitude: number;
  longitude: number;
}

/**
 * Credits: https://www.geodatasource.com/developers/javascript
 */
export function getDistanceInMiles(a: Coords, b: Coords): number {
  if (a.latitude === b.latitude && a.longitude === b.longitude) {
    return 0;
  } else {
    const radLatA = (Math.PI * a.latitude) / 180;
    const radLatB = (Math.PI * b.latitude) / 180;
    const theta = a.longitude - b.longitude;
    const radTheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radLatA) * Math.sin(radLatB) +
      Math.cos(radLatA) * Math.cos(radLatB) * Math.cos(radTheta);

    if (dist > 1) dist = 1;

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    return dist;
  }
}
