import { useLocation } from "react-router-dom";

type WarehouseSubRoute = "polygon" | "messages" | "settings";

/**
 * Extract the sub route from location pathname.
 *
 * e.g. if this is the URL:
 * warehouse/uk_london/polygon
 *
 * this function will return "polygon"
 * ```
 */
export function useWarehouseSubRoute() {
  const location = useLocation();
  const tab = location.pathname.split("/")[3];

  return (tab || "polygon") as WarehouseSubRoute;
}
