import { Box } from "@material-ui/core";
import { WarehouseStatus } from "@quickcommerceltd/zone";
import { FC } from "react";
import { getWarehouseStatusColor } from "./getWarehouseStatusColor";

interface Props {
  warehouseStatus: WarehouseStatus;
}

export const WarehouseStatusView: FC<Props> = (props) => {
  return (
    <Box
      sx={{
        width: 10,
        height: 10,
        bgcolor: getWarehouseStatusColor(props.warehouseStatus),
        borderRadius: "50%",
      }}
    />
  );
};
