import {
  Box,
  colors,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../Auth/AuthProvider";
import ChipIsDC from "../ChipIsDC/ChipIsDC";
import { DEFAULT_DELIVERY_TIME } from "../Warehouse/defaults";
import { getWarehouseStatus } from "../Warehouse/getWarehouseStatus";
import { useWarehouseSubRoute } from "../Warehouse/hooks/useWarehouseSubRoute";
import { useWarehouses } from "../Warehouse/WarehousesProvider";
import { WarehouseStatusView } from "../Warehouse/WarehouseStatusView";
import WarehouseWarning from "../Warehouse/WarehouseWarning";

const AppSidebarView = () => {
  const auth = useAuth();
  const classes = useStyles();
  const [warehouses] = useWarehouses();
  const location = useLocation();
  const subRoute = useWarehouseSubRoute();
  const isAdmin = auth.claims?.admin;

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box
        className={classes.toolbar}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <img src="/logo.png" alt="Zone" width={120} />
      </Box>
      <Divider />
      <List disablePadding>
        <ListItem
          component={Link}
          to="/"
          selected={location.pathname === "/"}
          button
          className={classes.nested}
        >
          {isAdmin && <ListItemText secondary="+ Create new store" />}
        </ListItem>
        {(warehouses ?? []).map((warehouse, index) => (
          <ListItem
            component={Link}
            to={`/warehouse/${warehouse.id}/${subRoute}`}
            key={index}
            selected={location.pathname.startsWith(
              `/warehouse/${warehouse.id}`,
            )}
            button
            className={classes.nested}
          >
            <Box display="flex" alignItems="center">
              <Box mr={1}>{warehouse.name}</Box>
              <Box mr={1} sx={{ fontSize: "smaller", color: colors.grey[500] }}>
                ({warehouse.shortName})
              </Box>
              {warehouse.isDistributionCenter && (
                <Box mr={1}>
                  <ChipIsDC nav />
                </Box>
              )}
              <Box display="flex" flex={1} />
              {warehouse.deliveryTime !== DEFAULT_DELIVERY_TIME && (
                <Box mr={1} sx={{ fontSize: "smaller", fontWeight: "bold" }}>
                  {warehouse.deliveryTime}
                </Box>
              )}
              {warehouse.warning && (
                <Box mr={1}>
                  <WarehouseWarning warning={warehouse.warning} />
                </Box>
              )}
              <Box mr={1}>
                <WarehouseStatusView
                  warehouseStatus={getWarehouseStatus(warehouse)}
                />
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default AppSidebarView;

export const SIDEBAR_WIDTH = 320;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: SIDEBAR_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: SIDEBAR_WIDTH,
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: "block",
  },
}));
