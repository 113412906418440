import {
  Box,
  Button,
  Card,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import {
  StorefrontData,
  StorefrontProvider,
  StorefrontStatus,
} from "@quickcommerceltd/zone";
import { FC, useCallback, useState } from "react";
import { WarehouseStatusView } from "../WarehouseStatusView";

interface Props {
  provider: StorefrontProvider;
  data: StorefrontData;
  onStorefrontStatusChanged: (
    provider: StorefrontProvider,
    status: StorefrontStatus,
  ) => Promise<unknown>;
}

export const StorefrontItem: FC<Props> = (props) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSetStorefrontStatus = useCallback(
    (newStatus: StorefrontStatus) => () => {
      setIsUpdating(true);
      props.onStorefrontStatusChanged(props.provider, newStatus).finally(() => {
        setIsUpdating(false);
      });
    },
    [props],
  );

  const isOpen = props.data.status === "OPEN";
  const buttonText = isOpen ? "Close" : "Open";

  return (
    <Card>
      <Box p={2} display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="body1">{props.provider}</Typography>
          <Box display="flex" alignItems="center" gridGap={4}>
            <WarehouseStatusView warehouseStatus={props.data.status} />
            <span style={{ textTransform: "capitalize" }}>
              {props.data.status.replace("_", " ").toLowerCase()}
            </span>
          </Box>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          disabled={isUpdating}
          onClick={handleSetStorefrontStatus(isOpen ? "CLOSED" : "OPEN")}
        >
          {isUpdating ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            buttonText
          )}
        </Button>
      </Box>
    </Card>
  );
};
