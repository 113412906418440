import { createContext, FC, useContext } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import firebase, { db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
interface AuthProviderValue {
  user: firebase.User | null;
  claims?: {
    admin: boolean | undefined;
    manager: boolean | undefined;
  };
}

const defaultAuthProviderValue: AuthProviderValue = {
  user: null,
  claims: undefined,
};

const AuthContext = createContext<AuthProviderValue>(defaultAuthProviderValue);

export let googleProvider = new firebase.auth.GoogleAuthProvider();

export const AuthProvider: FC = (props) => {
  const [user, isLoading] = useAuthState(firebase.auth());

  const [claims] = useDocumentData(
    user && db.collection("user_claims").doc(user.email),
  );

  const value: AuthProviderValue = {
    user,
    claims,
  };

  function renderSignInView() {
    return (
      <Card>
        <CardContent>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              firebase
                .auth()
                .signInWithRedirect(googleProvider)
                .catch(() => {});
            }}
          >
            Google Signin
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {isLoading ? null : user ? props.children : renderSignInView()}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
