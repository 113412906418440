import { FirebaseError } from "@firebase/util";
import { Box, CircularProgress } from "@material-ui/core";
import { Warehouse } from "@quickcommerceltd/zone";
import React, { FC, useContext } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../../firebase";

type WarehousesValue = [
  Warehouse[] | undefined,
  boolean,
  FirebaseError | undefined,
];

const defaultValue: WarehousesValue = [[], false, undefined];

const WarehousesContext = React.createContext(defaultValue);

export const useWarehouses = () => {
  const value = useContext(WarehousesContext);
  return value;
};

const WarehousesProvider: FC = ({ children }) => {
  const value = useCollectionData<Warehouse>(
    db.collection("warehouses").limit(500),
  );

  value?.[0]?.sort((a, b) => a.name.localeCompare(b.name));
  const isLoading = value?.[1];

  return (
    <WarehousesContext.Provider value={value}>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && children}
    </WarehousesContext.Provider>
  );
};

export default WarehousesProvider;
