import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import React, { FC } from "react";
import { warningColors } from "../Warehouse/WarningIcons";

const ColorPicker: FC<SelectProps> = (props) => {
  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel>Color</InputLabel>

      <Select
        fullWidth
        style={{
          backgroundColor: warningColors[(props.value as string) || ""],
        }}
        {...props}
      >
        {Object.keys(warningColors).map((name) => {
          return (
            <MenuItem
              key={name}
              value={name}
              style={{ backgroundColor: warningColors[name] }}
            >
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ColorPicker;
