import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import { Warehouse } from "@quickcommerceltd/zone";
import { useFormik } from "formik";
import { FC } from "react";
import * as yup from "yup";
import { updateWarehouseCallable } from "../callables/updateWarehouseCallable";

const validationSchema = yup.object().shape({
  address1: yup.string().required(),
  city: yup.string().required(),
  zip: yup.string().required(),
  countryCode: yup.string().required(),
  phone: yup.string().required(),
  latitude: yup.number().required(),
  longitude: yup.number().required(),
});

type FormData = yup.InferType<typeof validationSchema>;

interface Props {
  warehouse: Warehouse;
}

export const WarehouseAddressForm: FC<Props> = (props) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    isSubmitting,
  } = useFormik<FormData>({
    validationSchema,
    initialValues: {
      address1: props.warehouse.pickUpAddress.address1,
      city: props.warehouse.pickUpAddress.city,
      zip: props.warehouse.pickUpAddress.zip,
      countryCode: props.warehouse.pickUpAddress.countryCode,
      phone: props.warehouse.pickUpAddress.phone,
      latitude: props.warehouse.pickUpAddress.latitude,
      longitude: props.warehouse.pickUpAddress.longitude,
    } as FormData,
    enableReinitialize: true,
    onSubmit: async (values: FormData) => {
      try {
        await updateWarehouseCallable({
          id: props.warehouse.id,
          pickUpAddress: {
            address1: values.address1,
            city: values.city,
            countryCode: values.countryCode.toUpperCase(),
            latitude: values.latitude,
            longitude: values.longitude,
            phone: values.phone,
            zip: values.zip.toUpperCase(),
          },
        });
      } catch (error: any) {
        alert(error.message);
      }
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={1}>
        <TextField
          name="address1"
          onChange={handleChange}
          error={!!errors.address1}
          value={values.address1}
          placeholder="e.g. 154 Old Brompton Road"
          label="Address"
          variant="filled"
          fullWidth
        />
      </Box>
      <Box mb={1}>
        <TextField
          name="city"
          onChange={handleChange}
          error={!!errors.city}
          value={values.city}
          placeholder="e.g. London"
          label="City"
          variant="filled"
          fullWidth
        />
      </Box>
      <Box mb={1}>
        <TextField
          name="zip"
          onChange={handleChange}
          error={!!errors.zip}
          value={values.zip}
          placeholder="e.g. SW7 3LE"
          label="Zip"
          variant="filled"
          fullWidth
        />
      </Box>
      <Box mb={1}>
        <TextField
          name="countryCode"
          onChange={handleChange}
          error={!!errors.countryCode}
          value={values.countryCode}
          placeholder="e.g. GB (not uk)"
          label="Country Code (ISO 3166-1 alpha-2)"
          variant="filled"
          fullWidth
        />
      </Box>
      <Box mb={1}>
        <TextField
          name="phone"
          onChange={handleChange}
          error={!!errors.phone}
          value={values.phone}
          placeholder="e.g. +44 123 456 7890"
          label="Phone Number"
          variant="filled"
          fullWidth
        />
      </Box>
      <Box mb={1} display="flex" flexDirection="row">
        <Box flex={1}>
          <TextField
            name="latitude"
            onChange={handleChange}
            error={!!errors.latitude}
            value={values.latitude}
            placeholder="e.g. 51.49233"
            label="Latitude"
            type="number"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box flex={1} ml={1}>
          <TextField
            name="longitude"
            onChange={handleChange}
            error={!!errors.longitude}
            value={values.longitude}
            placeholder="e.g. -0.17781"
            label="Longitude"
            type="number"
            variant="filled"
            fullWidth
          />
        </Box>
      </Box>
      <Box display="flex" gridGap={16}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
          style={{ minWidth: "150px" }}
        >
          {isSubmitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <span>Update Address</span>
          )}
        </Button>
        <Button variant="text" onClick={handleReset}>
          RESET
        </Button>
      </Box>
    </form>
  );
};
