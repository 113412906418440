import { Box } from "@material-ui/core";
import { Warehouse } from "@quickcommerceltd/zone";
import { useMemo } from "react";
import { usePolygons } from "./PolygonsProvider";

const WarehouseStatus = ({ warehouse }: { warehouse: Warehouse }) => {
  const [polygons] = usePolygons();
  const activePath = useMemo(() => {
    const polygon = polygons?.find((polygon) => polygon.id === warehouse.id);
    const activePath = polygon?.activePathId
      ? polygon.paths.find((path) => path.id === polygon?.activePathId)
      : undefined;
    return activePath;
  }, [polygons, warehouse]);

  return (
    <Box>
      <Box padding={1} margin={-1} mr={0} fontSize="0.9em">
        Delivery Promise: <b>{warehouse.deliveryTime} min</b>
        {" · "}
        Order Stack Size: <b>{warehouse.currentOrderStackSize || 0}</b>
        {" · "}
        Polygon: <b>{activePath?.name || <i>inactive</i>}</b>
      </Box>
    </Box>
  );
};

export default WarehouseStatus;
