import { CircularProgress } from "@material-ui/core";
import { WarehousePolygon } from "@quickcommerceltd/zone";
import { LoadScript } from "@react-google-maps/api";
import { FC } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../../firebase";
import { useWarehouseFromRoute } from "./hooks/useWarehouseFromRoute";
import WarehousePolygonView from "./WarehousePolygonView";

export const WarehousePolygonPage: FC = () => {
  const warehouse = useWarehouseFromRoute();
  const [polygon, isPolygonLoading] = useDocumentData<WarehousePolygon>(
    warehouse ? db.doc(`warehousePolygons/${warehouse.id}`) : null,
  );

  if (isPolygonLoading) {
    return <CircularProgress />;
  }

  if (!polygon || !warehouse) {
    return <div>Warehouse Polygon Not Found</div>;
  }

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey="AIzaSyAoDWn9p0Sx_CEM3bCXotYZmfcMauTWMYA"
      language="en"
      loadingElement={<CircularProgress />}
      region="gb"
    >
      <WarehousePolygonView
        key={warehouse.id}
        warehouse={warehouse}
        polygon={polygon}
      />
    </LoadScript>
  );
};
