import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

/**
 * Get Firebase Callable Cloud Function
 */
export const getCallable = <RequestData, ResponseData>(
  name: string,
  isResponseData?: (obj: unknown) => obj is ResponseData,
) => {
  const callable = httpsCallable<RequestData, ResponseData>(functions, name);

  return async (request: RequestData) => {
    const result = await callable(request);

    if (isResponseData) {
      if (!isResponseData(result.data)) {
        throw Error("Invalid response data");
      }
    }

    return result.data;
  };
};
