import {
  Box,
  Card,
  Switch,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { StoreWarning } from "@quickcommerceltd/zone";
import { FC, useState } from "react";
import { StoreWarningForm } from "./StoreWarningForm";
import { StoreWarningView } from "./StoreWarningView";

interface Props {
  value: StoreWarning;
  enabled: boolean;
  disabled: boolean;
  onChange: (value: StoreWarning) => void;
  onToggle: (checked: boolean) => void;
  onDelete: () => void;
  canToggle?: boolean;
}

export const StoreWarningItem: FC<Props> = ({
  value,
  enabled,
  disabled,
  onChange,
  onToggle,
  onDelete,
  canToggle,
}) => {
  const [isEditing, setEditing] = useState(false);

  return (
    <Box mb={2}>
      <Card style={{ boxShadow: enabled ? "0 0 0 4px #02BAFF" : undefined }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <IconButton
              color="primary"
              disabled={disabled}
              onClick={() => {
                setEditing(!isEditing);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton color="primary" onClick={onDelete} disabled={disabled}>
              <Delete />
            </IconButton>
          </Box>
          <Box mx={1}>
            <FormControlLabel
              label="Enabled"
              control={
                <Switch
                  color="primary"
                  checked={enabled}
                  disabled={!canToggle}
                  onChange={(event) => {
                    onToggle(event.target.checked);
                  }}
                />
              }
            />
          </Box>
        </Box>
        {!isEditing && <StoreWarningView value={value} />}
      </Card>
      {isEditing && (
        <StoreWarningForm
          value={value}
          onClose={() => {
            setEditing(false);
          }}
          onSubmit={(value) => {
            onChange(value);
            setEditing(false);
          }}
        />
      )}
    </Box>
  );
};
